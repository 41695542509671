<template>
  <div class="row">
    <div class="col-12" style="text-align: center;">
      <q-btn-group flat>
        <q-btn color="grey-8" class="calMoveBtn" outline rounded icon="arrow_back_ios" label="" @click="onPrev">
        </q-btn>
        <q-btn color="grey-8" class="calMoveBtn calMoveBtn2" outline rounded :label="title" @click="onToday" style="width: 130px;">
        </q-btn>
        <q-btn color="grey-8" class="calMoveBtn" outline rounded icon-right="arrow_forward_ios" label="" @click="onNext">
        </q-btn>
      </q-btn-group>
        <q-btn color="grey-8" class="btnCalendarSetting" flat rounded icon="settings" label="" @click="openSetting"></q-btn>
    </div>
    <div class="col-12 rightCalendar" style="min-height: {rightHeight};">
      <q-calendar
        ref="calendar"
        v-model="selectedDate"
        view="month"
        locale="ko-kr"
        :day-height="dayHeight"
        :day-min-height="dayMinHeight"
        @change="onChange"
        @click:day2="onClickNewDay"
        animated
        v-touch-swipe.mouse.left.right="handleSwipe"
        transition-prev="slide-right"
        transition-next="slide-left"
        short-weekday-label
        short-month-label
        :show-month-label="false"
      >
      <template #week="{ week }">
        <template>
          <q-badge
            v-for="(computedEvent, index) in getWeekEvents(week)"
            :key="index"
            class="q-row-event"
            :class="badgeClasses(computedEvent, 'day')"
            :style="badgeStyles(computedEvent, 'day', week.length)"
            @click="openDetail(computedEvent)"
          >
            <template v-if="computedEvent.event">
              <span class="ellipsis">{{ computedEvent.event.title }}</span>
            </template>
          </q-badge>
        </template>
      </template>
      </q-calendar>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="prompt" persistent position="bottom">
      <q-card style="width: 100%">
        <q-card-section>
          <q-btn flat @click="closePopupSetting" round dense icon="close" class="rightCloseBtn"></q-btn>
          <q-list>
            <q-item v-ripple>
              <q-item-section>
                <!-- 내 캘린더 -->
                <span class="mycalendar-title">내 캘린더</span>
              </q-item-section>
              <q-item-section avatar>
                <q-btn color="grey" flat rounded icon="add" label="">
                  <q-popup-proxy transition-show="flip-up" transition-hide="flip-down" ref="calproxy0">
                    <!-- 새 캘린더 -->
                    <c-card title="새 캘린더" class="cardClassDetailForm" :radiusFlag="false" style="width: 300px">
                      <template slot="card-button">
                        <q-btn-group outline >
                          <c-btn label="저장" icon="save" @btnClicked="saveMst(newMst)" />
                        </q-btn-group>
                      </template>
                      <template slot="card-detail">
                        <div class="col-12">
                          <!-- 캘린더 명 -->
                          <c-text
                            :required="true"
                            :editable="editable"
                            label="캘린더 명"
                            name="calendarMstName"
                            v-model="newMst.calendarMstName">
                          </c-text>
                        </div>
                        <div class="col-3" style="text-align: center;"
                          v-for="(color, index) in colors"
                          :key="index">
                          <q-btn 
                            :color="color" 
                            rounded 
                            :icon="newMst.calendarMstColor == color ? 'check' : ''" 
                            @click="newMst.calendarMstColor = color"
                            label="" />
                        </div>
                        <div class="col-12">
                          <br/>
                        </div>
                      </template>
                    </c-card>
                  </q-popup-proxy>
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item v-ripple
              v-for="(mst, index) in calMsts"
              :key="index">
              <q-item-section>
                <q-checkbox keep-color v-model="mst.mstCheck" :label="mst.calendarMstName" :color="mst.calendarMstColor" @input="checkMstId" />
              </q-item-section>
              <q-item-section avatar>
                <q-btn color="grey" flat rounded icon="more_vert" label="">
                  <q-popup-proxy transition-show="flip-up" transition-hide="flip-down" :ref="'calproxy' + mst.calendarMstId">
                    <!--캘린더 설정-->
                    <c-card title="캘린더 설정" class="cardClassDetailForm" :radiusFlag="false" style="width: 300px">
                      <template slot="card-button">
                        <q-btn-group outline >
                          <c-btn label="삭제" icon="delete" @btnClicked="deleteMst(mst)" v-if="mst.defaultFlag == 'N'"/>
                          <c-btn label="저장" icon="save" @btnClicked="updateMst(mst)" />
                        </q-btn-group>
                      </template>
                      <template slot="card-detail">
                        <div class="col-12">
                          <!-- 캘린더 명 -->
                          <c-text
                            :required="true"
                            :editable="editable"
                            label="캘린더 명"
                            name="calendarMstName"
                            v-model="mst.calendarMstName">
                          </c-text>
                        </div>
                        <div class="col-3" style="text-align: center;"
                          v-for="(color, index) in colors"
                          :key="index">
                          <q-btn 
                            :color="color" 
                            rounded 
                            :icon="mst.calendarMstColor == color ? 'check' : ''" 
                            @click="mst.calendarMstColor = color"
                            label="" />
                        </div>
                        <div class="col-12">
                          <br/>
                        </div>
                      </template>
                    </c-card>
                  </q-popup-proxy>
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import QCalendarm from '@quasar/quasar-ui-qcalendar'
import {QCalendar} from '@quasar/quasar-ui-qcalendar'
import "@quasar/quasar-ui-qcalendar/dist/index.css"
export default {
  name: 'calendar',
  components: {
    QCalendar,
  },
  data() {
    return {
      prompt: false,
      checks: [],
      leftHeight: '',
      rightHeight: '',
      searchParam: {
        userId: null,
        startDt: '',
        endDt: '',
        calMstIds: [],
      },
      newMst: {
        userId: '',
        calendarMstId: '',
        calendarMstName: '',
        calendarMstColor: '',
        defaultFlag: 'N',
        mstCheck: true,
      },
      popupOptions: {
        isFull: true,
        suffixChip: '',
        target: null,
        title: '',
        width: '900px',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      calMsts: [],
      editable: true,
      mstlistUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      scheduleListUrl: '',
      title: '',
      dateFormatter: undefined,
      start: undefined,
      selectedDate: '',
      events: [],
      monthDaysFirst: [],
      monthDaysLast: [],
      dayHeight: 130,
      dayMinHeight: 130,
      colors: ['deep-purple', 'blue', 'green', 'orange', 'blue-grey', 'indigo', 'purple', 'teal', 'light-blue', 'red', 'yellow-9', 'grey-6'],
      dragging: false, // used for drag-and-drop
      ignoreNextSwipe: false // used for drag-and-drop
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.leftHeight = (window.innerHeight - 124) + 'px';
      this.rightHeight = (window.innerHeight - 146) + 'px';
      this.scheduleListUrl = selectConfig.mdm.cal.schedule.list.url;
      this.mstlistUrl = selectConfig.mdm.cal.mst.list.url;
      this.mstSaveUrl = transactionConfig.mdm.cal.mst.insert.url;
      this.mstDeleteUrl = transactionConfig.mdm.cal.mst.delete.url;
      this.getMsts();
    },
    openSetting() {
      this.prompt = true;
    },
    closePopupSetting() {
      this.prompt = false;
    },
    checkMstId() {
      this.getList();
    },
    getList() {
      this.events = [];
      this.searchParam.userId = this.$store.getters.user.userId;
      
      if (this.$refs.calendar !== undefined) {
        this.searchParam.startDt = this.$refs.calendar.lastStart;
        this.searchParam.endDt = this.$refs.calendar.lastEnd;
      } else {
        this.searchParam.startDt = this.start.year + '-' + this.start.month + '-01';
        this.searchParam.endDt = this.start.year + '-' + this.start.month + '-31';
      }
      this.searchParam.calMstIds = [];
      this.$_.forEach(this.calMsts, _mst => {
        if (_mst.mstCheck) {
          this.searchParam.calMstIds.push(_mst.calendarMstId);
        }
      })

      this.$http.url = this.scheduleListUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.events.push({
            title: _item.calendarScheduleTitle,
            start: _item.startDt,
            end: _item.endDt,
            color: _item.calendarMstColor,
            calendarScheduleId: _item.calendarScheduleId,
            userId: _item.userId,
          });
        })
      },);
    },
    getMsts() {
      this.$http.url = this.mstlistUrl;
      this.$http.param = {userId: this.$store.getters.user.userId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.calMsts = _result.data;
        this.getList();
      },);
    },
    saveMst(data) {
      if (data.calendarMstName == '') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '캘린더 명을 입력하세요', // 캘린더 명을 입력하세요
          type: 'warning', // success / info / warning / error
        });
      } else {
        data.userId = this.$store.getters.user.userId;
        this.$http.url = this.mstSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = data;
        this.$http.request(() => {
          this.getMsts();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$refs.calproxy0.hide();
        },);
      }
    },
    updateMst(data) {
      if (data.calendarMstName == '') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '캘린더 명을 입력하세요', // 캘린더 명을 입력하세요
          type: 'warning', // success / info / warning / error
        });
      } else {
        data.userId = this.$store.getters.user.userId;
        this.$http.url = this.mstSaveUrl;
        this.$http.type = 'PUT';
        this.$http.param = data;
        this.$http.request(() => {
          this.getMsts();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$refs['calproxy' + data.calendarMstId][0].hide();
        },);
      }
    },
    deleteMst(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '해당 캘린더의 일정도 모두 삭제됩니다.\r\n삭제하시겠습니까?', // 해당 캘린더의 일정도 모두 삭제됩니다.\r\n삭제하시겠습니까?
        type: 'warning', // success / info / warning / error
        confirmCallback: () => {
          this.$http.url = this.$format(this.mstDeleteUrl, this.$store.getters.user.userId, data.calendarMstId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getMsts();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$refs['calproxy' + data.calendarMstId][0].hide();
          },);
        },
        cancelCallback: () => {
        },
      });
    },
    onChange ({ start }) {
      this.start = start
      this.updateTitle()
      this.getList();
    },
    getSearch() {
    },
    handleSwipe ({ evt, ...info }) {
      if (this.dragging === false) {
        if (info.duration >= 30 && this.ignoreNextSwipe === false) {
          if (info.direction === 'right') {
            this.onPrev()
          }
          else if (info.direction === 'left') {
            this.onNext()
          }
        }
        else {
          this.ignoreNextSwipe = false
        }
      }
      evt.cancelable !== false && evt.preventDefault()
      evt.stopPropagation()
    },
    onPrev () {
      this.$refs.calendar.prev()
    },
    onNext () {
      this.$refs.calendar.next()
    },
    onToday() {
      this.$refs.calendar.moveToToday()
    },
    updateTitle () {
      this.title = this.start.year + '년 ' + this.start.month + '월'
    },
    isCssColor (color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
    },
    badgeClasses (infoEvent, type) {
      const color = infoEvent.event !== undefined ? infoEvent.event.color : 'transparent'
      const cssColor = this.isCssColor(color)
      const isHeader = type === 'header'
      return {
        [`text-white bg-${color}`]: !cssColor,
        'full-width': !isHeader && (!infoEvent.side || infoEvent.side === 'full'),
        'left-side': !isHeader && infoEvent.side === 'left',
        'right-side': !isHeader && infoEvent.side === 'right',
        'cursor-pointer': infoEvent.event !== undefined && infoEvent.event.type !== 'D',
        'q-day-event-void': infoEvent.event === undefined
      }
    },
    badgeStyles (infoEvent, type, weekLength, timeStartPos, timeDurationHeight) {
      const s = {}
      if (timeStartPos) {
        s.top = timeStartPos(infoEvent.event.time) + 'px'
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(infoEvent.event.duration) + 'px'
      }
      if (infoEvent.size !== undefined) {
        s.width = ((100 / weekLength) * infoEvent.size) + '% !important'
      }
      return s
    },
    getWeekEvents (week) {
      const tsFirstDay = QCalendarm.parsed(week[0].date + ' 00:00')
      const tsLastDay = QCalendarm.parsed(week[week.length - 1].date + ' 23:59')
      const firstDay = QCalendarm.getDayIdentifier(tsFirstDay)
      const lastDay = QCalendarm.getDayIdentifier(tsLastDay)

      const eventsWeek = []
      this.events.forEach((event, id) => {
        const tsStartDate = QCalendarm.parsed(event.start + ' 00:00')
        const tsEndDate = QCalendarm.parsed(event.end + ' 23:59')
        const startDate = QCalendarm.getDayIdentifier(tsStartDate)
        const endDate = QCalendarm.getDayIdentifier(tsEndDate)

        if (this.isBetweenDatesWeek(startDate, endDate, firstDay, lastDay)) {
          const left = QCalendarm.daysBetween(tsFirstDay, tsStartDate, true)
          const right = QCalendarm.daysBetween(tsEndDate, tsLastDay, true)

          eventsWeek.push({
            id, // index event
            left, // Position initial day [0-6]
            right, // Number days available
            size: week.length - (left + right), // Size current event (in days)
            event // Info
          })
        }
      })
      const events = []
      if (eventsWeek.length > 0) {
        const infoWeek = eventsWeek.sort((a, b) => a.left - b.left)
        infoWeek.forEach((event, i) => {
          this.insertEvent(events, week.length, infoWeek, i, 0, 0)
        })
      }
      return events
    },
    insertEvent (events, weekLength, infoWeek, index, availableDays, level) {
      const iEvent = infoWeek[index]
      if (iEvent !== undefined && iEvent.left >= availableDays) {
        if (iEvent.left - availableDays) {
          events.push({ size: iEvent.left - availableDays })
        }
        events.push({ size: iEvent.size, event: iEvent.event })
        if (level !== 0) {
          infoWeek.splice(index, 1)
        }
        const currentAvailableDays = iEvent.left + iEvent.size
        if (currentAvailableDays < weekLength) {
          const indexNextEvent = QCalendarm.indexOf(infoWeek, e => e.id !== iEvent.id && e.left >= currentAvailableDays)
          this.insertEvent(
            events,
            weekLength,
            infoWeek,
            indexNextEvent !== -1 ? indexNextEvent : index,
            currentAvailableDays,
            level + 1
          )
        }
      }
      else {
        events.push({ size: weekLength - availableDays })
      }
    },
    isBetweenDates (date, start, end) {
      return date >= start && date <= end
    },
    isBetweenDatesWeek (dateStart, dateEnd, weekStart, weekEnd) {
      return (
        (dateEnd < weekEnd && dateEnd >= weekStart) ||
          dateEnd === weekEnd ||
          (dateEnd > weekEnd && dateStart <= weekEnd)
      )
    },
    onClickNewDay(day) {
      this.popupOptions.target = () => import(`${"./calendarDetail.vue"}`);
      this.popupOptions.title = '일정 등록'; // 일정 등록
      this.popupOptions.param = {
        startDt: day ? day.scope.timestamp.date : '',
        endDt: day ? day.scope.timestamp.date : '',
        calendarScheduleId: null,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    openDetail(row) {
      this.popupOptions.target = () => import(`${"./calendarDetail.vue"}`);
      this.popupOptions.title = '일정 상세'; // 일정 상세
      this.popupOptions.param = {
        startDt: row ? row.event.start : '',
        endDt: row ? row.event.end : '',
        calendarScheduleId: row ? row.event.calendarScheduleId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
